import { withHandlers, withState } from 'recompose';
import React from 'react';
import Confetti from 'react-dom-confetti';
import cx from 'classnames';
import Modal, * as M from 'theme/Modal';
import * as emojis from 'theme/emojis';
import { composeComponent } from 'utils/react-tools';

const min = 1;
const max = 5;
const optionsCount = Math.abs(min) + Math.abs(max);

const RIG_OPTIONS = ['Frustrated', 'Unhappy', 'Meh', 'Happy', 'Pumped Up'];

const FeedbackOptions = [
  'Partner match',
  'Quality of questions',
  'Tech issues',
  'Timing constraints',
  'Personal reasons',
  'Other',
];

const config = {
  angle: 45,
  spread: 700,
  startVelocity: 50,
  elementCount: '200',
  dragFriction: 0.12,
  duration: 3000,
  stagger: '0',
  width: '10px',
  height: '10px',
  perspective: '1000px',
  colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
};

const mobileConfig = {
  startVelocity: 30,
};

export default composeComponent(
  'Conversation.QualityRate',
  withState('rate', 'setRate', null),
  withState('modalDismissed', 'dismissModal', false),
  withState('feedback', 'setFeedback', ''),
  withState('warningVisible', 'showWarning', false),
  withState('loading', 'setLoading', false),
  withState('error', 'setError', null),
  withState('focused', 'focus', false),
  withState('selectedFeedbackOptions', 'setFeedbackOptions', []),
  withState('otherOption', 'setOtherOption', ''),
  withHandlers({
    finish:
      ({
        rate,
        feedback,
        showWarning,
        submit,
        setLoading,
        setError,
        dismissModal,
        selectedFeedbackOptions,
        otherOption,
      }) =>
      () => {
        if (
          (!rate && rate !== 0) ||
          (rate <= 2 &&
            (!selectedFeedbackOptions.length || (selectedFeedbackOptions.indexOf('Other') > -1 && !otherOption)))
        ) {
          showWarning(true);
        } else {
          setLoading(true);
          const mappedFeedback =
            rate > 2
              ? feedback
              : ''.concat('{', selectedFeedbackOptions.map((o) => (o == 'Other' ? otherOption : o)).join(', '), '}');
          submit(rate, mappedFeedback).then(
            () => setLoading(false),
            (err) => {
              setError(err);
              setLoading(false);
              dismissModal(true);
            }
          );
        }
      },
    setRate:
      ({ setRate, showWarning, setError }) =>
      (rate) => {
        setRate(rate);
        setError(null);
        showWarning(false);
      },
    setFeedback:
      ({ setFeedback }) =>
      (e) => {
        setFeedback(e.target.value);
      },
    selectFeedbackOption:
      ({ setFeedbackOptions, selectedFeedbackOptions, setOtherOption, showWarning }) =>
      (option) => {
        const i = selectedFeedbackOptions.indexOf(option);
        showWarning(false);
        if (i == -1) {
          setFeedbackOptions([...selectedFeedbackOptions, option]);
        } else {
          setFeedbackOptions([].concat(selectedFeedbackOptions.slice(0, i), selectedFeedbackOptions.slice(i + 1)));
          option == 'Other' && setOtherOption('');
        }
      },
  }),
  withHandlers({
    handleKeyDown:
      ({ focused, setRate, rate, loading }) =>
      (e) => {
        if (!focused || loading) return;
        const v = rate || 0;
        switch (e.key) {
          case 'ArrowLeft':
            if (v - 1 >= min) setRate && setRate(v - 1);
            break;
          case 'ArrowRight':
            if (v + 1 <= max) setRate && setRate(v + 1);
            break;
        }
      },
  }),
  ({
    conversation,
    t,
    rate,
    feedback,
    showWarning,
    setFeedback,
    setRate,
    warningVisible,
    finish,
    error,
    loading,
    handleKeyDown,
    focus,
    modalDismissed,
    user,
    selectFeedbackOption,
    selectedFeedbackOptions,
    setOtherOption,
    otherOption,
  }) => {
    const organizationName = user && user.organization && user.organization.name;
    const partner = conversation.partner;
    const qualityRate = conversation.me.qualityRate;
    const confettiVisible = qualityRate == 5;

    const handleRate = (rate) => {
      !loading && setRate(rate);
    };

    return (
      <>
        <div className="Conversation__confetti">
          <Confetti active={confettiVisible} config={config} />
        </div>
        <div className="Conversation__confetti mobile">
          <Confetti active={confettiVisible} config={{ ...config, ...mobileConfig }} />
        </div>
        <Modal
          isOpen={!qualityRate && qualityRate !== 0 && !modalDismissed}
          className="Conversation__qualityModal no-padding"
        >
          <M.Header className="Conversation__qualityModalTitle">
            {partner && t.find('peerconversation.qualityRate.title', partner.member)}
          </M.Header>
          <M.Content className="Conversation__qualityModalBody">
            <div
              className="Conversation__qualityModalOptions"
              tabIndex="0"
              onFocus={() => {
                focus(true);
                setRate(rate || max);
              }}
              onBlur={() => focus(false)}
              onKeyDown={handleKeyDown}
            >
              {new Array(optionsCount - 1).fill(0).map((_, i) => {
                const optionI = i + 1;
                const emojiSrcGray = emojis[RIG_OPTIONS[i].replace(' ', '') + '_gray'];
                const emojiSrc = emojis[RIG_OPTIONS[i].replace(' ', '')];

                return (
                  <div
                    key={i}
                    tabIndex="0"
                    role="radio"
                    aria-checked={rate == optionI}
                    className={cx('Conversation__qualityModalOption', { selected: rate == optionI })}
                    onClick={() => handleRate(optionI)}
                    onKeyDown={(e) => e.key == 'Enter' && handleRate(optionI)}
                  >
                    <img src={emojiSrcGray} alt="" className="img-emoji" aria-hidden />
                    <img src={emojiSrc} alt="" className="img-emoji color" />
                    <span>{t.find(`peerconversation.qualityRate.options.option${optionI}`)}</span>
                  </div>
                );
              })}
            </div>
            {(rate == null || rate > 2) && (
              <div className="Conversation__qualityModalFeedback">
                <textarea
                  value={feedback}
                  onChange={setFeedback}
                  placeholder={`Your program leaders at ${organizationName} would love to hear why…`}
                  aria-label="quality feedback"
                />
              </div>
            )}
            {rate != null && rate <= 2 && (
              <fieldset className="Conversation__qualityModalFeedback">
                <legend className="p-new">
                  We’d love to know more about why today’s conversation was{' '}
                  {t.find(`peerconversation.qualityRate.options.option${rate}`).toLowerCase()} so we can make this
                  experience better for everyone. Where could it have been improved?
                </legend>
                <div>
                  {FeedbackOptions.map((o, i) => {
                    const checked = selectedFeedbackOptions.indexOf(o) > -1;
                    return (
                      <label key={o} className="Conversation__qualityModalRadio">
                        <input
                          type="checkbox"
                          checked={checked}
                          onChange={() => selectFeedbackOption(o)}
                          onKeyDown={(e) => e.key == 'Enter' && selectFeedbackOption(o)}
                        />
                        <span>
                          <span>{o}</span>
                        </span>
                      </label>
                    );
                  })}
                </div>
                {selectedFeedbackOptions.indexOf('Other') > -1 && (
                  <input
                    className="Conversation__qualityModalInput"
                    value={otherOption}
                    onChange={(e) => {
                      setOtherOption(e.target.value);
                      showWarning(false);
                    }}
                    placeholder={`Your program leaders at ${
                      user && user.organization && user.organization.name
                    } would love to hear why…`}
                  />
                )}
              </fieldset>
            )}
            <div className="Conversation__qualityModalAction">
              {warningVisible && (
                <div className="Conversation__qualityModalLabel small">
                  {t.find('peerconversation.qualityRate.warning')}
                </div>
              )}
              {error && (
                <div className="Conversation__qualityModalLabel small">
                  {t.find('peerconversation.qualityRate.error')}
                </div>
              )}
              <button
                className="btn btn_primary btn_solid_bluePurple"
                onClick={finish}
                disabled={warningVisible || loading}
              >
                {loading ? (
                  <span className="far fa-circle-notch fa-spin" />
                ) : (
                  <span>{t.find('peerconversation.qualityRate.button')}</span>
                )}
              </button>
            </div>
          </M.Content>
        </Modal>
      </>
    );
  }
);
