import { getAuth, getJWTToken } from 'api';
import moment from 'moment-timezone';
import posthog from 'posthog-js';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Collapse } from 'react-collapse';
import { Link, useHistory } from 'react-router-dom';
import cx from 'classnames';
import Tippy from '@tippyjs/react';
import * as states from 'utils/states';
import TranslationsContext from 'translations';
import { usePcpV2Url } from 'api/usePcpV2Url';
import './SetCard.sass';
import 'tippy.js/dist/tippy.css';

const stopReschedulingState = states.Finished;

const ActiveConversationDetailsBox = ({children, date}) => {
  if (!date) return null;

  return (
    <div className="SetCardNew__schedule p-new">
      <b>{moment(date).format('MMMM D')},</b>
      {moment(date).format('hh:mm A')}
      {children}
    </div>
  )
}

const SetCard = ({ group, sets = [], user, toastVisible, showToast, setActiveSet, setActiveConversation }) => {
  const timeoutRef = useRef();

  const [token, setToken] = useState();
  const [activeConvIndex, setActiveConvIndex] = useState(-1);
  const [activeSetIndex, setActiveSetIndex] = useState(0);

  const t = useContext(TranslationsContext);
  const history = useHistory();
  const pcpV2Url = usePcpV2Url();

  useEffect(() => {
    const auth = getAuth() || {};
    getJWTToken(auth.jwt).then(setToken);
  }, []);

  useEffect(() => {
    if (toastVisible) {
      timeoutRef.current = setTimeout(() => showToast(false), 8000);
    }
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [toastVisible]);

  useEffect(() => {
    showToast(false);
  }, [group.peer.memberId, activeSetIndex]);

  const setsMatched = useMemo(() => {
    const mapppedSets = group.sets.map((g) => {
      const {
        dateFinished,
        dateCompleteBy,
        archived,
        programSetLabel,
        finished,
        convs: conversations = [],
        peer = {},
      } = g;
      const set = sets.find((s) => {
        return s.programSetLabel == g.programSetLabel;
      });
      const sortedConversations = conversations
        .sort((a, b) => {
          if (!set || !set.definitions) return 0;
          if (a.order && b.order) return a.order - b.order;
          return (
            set.definitions.findIndex((d) => d.label == a.convLabel) -
            set.definitions.findIndex((d) => d.label == b.convLabel)
          );
        })
        .map((conv) => {
          const me = conv.participant.find((p) => user && p.member.memberId == user.id);
          const partner = conv.participant.find((p) => user && p.member.memberId != user.id);
          const [programLabel, setLabel, convLabel] = conv.label.split('/');
          return { ...conv, me, partner, convLabel };
        });

      return {
        ...g,
        set,
        convs: sortedConversations,
      };
    });
    return {
      ...group,
      sets: mapppedSets,
    };
  }, [group, sets, user]);

  const groupSet = useMemo(() => {
    return setsMatched.sets[activeSetIndex];
  }, [activeSetIndex, setsMatched]);

  useEffect(() => {
    setActiveSet(groupSet);
  }, [groupSet]);

  useEffect(() => {
    if (!groupSet) return;
    const activeConversationIndex = groupSet.convs.findIndex((conv) => {
      return conv.state != states.Finished;
    });
    if (activeConversationIndex == -1) {
      setActiveConvIndex(0);
      return;
    }
    setActiveConvIndex(activeConversationIndex);
    setActiveConversation(groupSet.convs[activeConversationIndex]);
  }, [group.peer.memberId, groupSet]);

  useEffect(() => {
    setActiveSetIndex(0);
  }, [group.peer.memberId]);

  const openScheduler = (conversation) => {
    if (!conversation) return;
    history.push(`${location.pathname}?action=reschedule&id=${conversation.id}`);
  };

  if (!user || !groupSet) return null;

  const ConvButton = ({ conversationId, children }) => {
    return (
      <a className="btn-primary pink" href={`${pcpV2Url}/${conversationId}?access_token=${token}`}>
        {children}
      </a>
    );
  };

  return (
    <div className="SetCardNew">
      {setsMatched.sets?.map((s, i) => {
        const activeConversation = s.convs[activeConvIndex];
        const activeConversationFinished = activeConversation && activeConversation.state == states.Finished;
        const previousFinished =
          activeConvIndex == 0 ||
          (s.convs[activeConvIndex - 1] && s.convs[activeConvIndex - 1].state == states.Finished);
        const beginBtnVisible =
          activeConversation &&
          states.isBefore(activeConversation.state, states.Finished) &&
          (activeConvIndex == 0 || previousFinished);
        const scheduleBtnVisible =
          activeConversation &&
          states.isBefore(activeConversation.state, stopReschedulingState) &&
          !activeConversation.scheduleDate;

        return (
          <div className="card-shadow" key={i} aria-label="Peer coaching set">
            <div className="SetCardNew__header">
              {activeSetIndex !== i && (
                <button
                  className="btn-icon"
                  onClick={() => setActiveSetIndex(i)}
                  aria-label={
                    'Peer coaching set: ' +
                    t.find(`peerconversation.set.${s.set?.label}.title`) +
                    ' - select set'
                  }
                >
                  <span className="material-icons" aria-hidden>
                    add
                  </span>
                </button>
              )}
              <div className="section-intro">{t.find(`peerconversation.set.${s.set?.label}.title`)}</div>
              {!s.archived && !s.finished && (
                <div className="btn-small pink">Complete by {moment(s.dateCompleteBy).format('MMMM D')}</div>
              )}
              {!s.archived && s.finished && (
                <div className="btn-small pink">Completed {moment(s.dateFinished).format('MMMM D')}</div>
              )}
              {s.archived && <div className="btn-small">Wrap up</div>}
            </div>
            <Collapse isOpened={activeSetIndex == i} initialStyle={{ height: 'auto', overflow: 'initial' }}>
              <div className="SetCardNew__progress">
                <Tippy
                  visible={toastVisible && activeSetIndex == i}
                  content="You can also schedule out your next conversations!"
                  placement="right"
                  className="SetCardNew__tip"
                >
                  <div className="SetCardNew__progressDots">
                    {s.convs.map((conv, i) => {
                      const active = activeConversation && conv.id === activeConversation.id;
                      const finished = conv.state == states.Finished;
                      const scheduled = conv.scheduleDate && conv.state != states.Finished;

                      return (
                        <button
                          key={conv.id}
                          className={cx('SetCardNew__progress-item', { active })}
                          onClick={() => {
                            setActiveConvIndex(i)
                            setActiveConversation(conv);
                          }}
                          aria-label={
                            'Peer coaching set: ' +
                            t.find(`peerconversation.set.${s.set?.label}.title`) +
                            ' - conversation ' +
                            (i + 1)
                          }
                          aria-current={active}
                        >
                          <div
                            className={cx('SetCardNew__progress-dot', { active, finished, scheduled })}
                            aria-hidden
                          >
                            {i + 1}
                          </div>
                        </button>
                      );
                    })}
                  </div>
                </Tippy>
              </div>
              {activeConversation && (
                <div className="SetCardNew__details">
                  <div className="section-intro">
                    {t.find(`peerconversation.conversation.${activeConversation.convLabel}.title`)}
                  </div>
                  {activeConversationFinished
                    ? <ActiveConversationDetailsBox date={activeConversation.modifiedAt} />
                    : <ActiveConversationDetailsBox date={activeConversation.scheduleDate}>
                        {states.isBefore(activeConversation.state, stopReschedulingState) && (
                          <button
                            className="btn-icon"
                            onClick={() => openScheduler(activeConversation)}
                            aria-label="Edit schedule"
                          >
                            <i className="material-icons-outlined" aria-hidden>
                              edit
                            </i>
                            <span className="p-new">Change</span>
                          </button>
                        )}
                      </ActiveConversationDetailsBox>
                  }
                  <p className="p-new">
                    {t.find(`peerconversation.conversation.${activeConversation.convLabel}.description`)}
                  </p>
                </div>
              )}
              {(scheduleBtnVisible || beginBtnVisible || activeConversationFinished) && (
                <div className="SetCardNew__buttons">
                  {!s.archived && !s.finished && (
                    <Link to="/unmatch" className="btnLink btnLink_underlined btnLink_primary">
                      I need to unmatch
                    </Link>
                  )}
                  <div aria-hidden className="spacer" />
                  {scheduleBtnVisible && (
                    <button
                      className="btn-secondary"
                      onClick={() => openScheduler(activeConversation)}
                      aria-label="Open scheduler"
                    >
                      <i className="material-icons" aria-hidden>
                        event
                      </i>
                      <span>Schedule</span>
                    </button>
                  )}
                  {beginBtnVisible && (
                    <ConvButton conversationId={activeConversation.id}>
                      <i className="material-icons" aria-hidden>
                        videocam
                      </i>
                      {activeConversation?.me?.state == states.Started ? (
                        <span>Continue</span>
                      ) : (
                        <span>Begin</span>
                      )}
                    </ConvButton>
                  )}
                  {activeConversationFinished && (
                    <Link className="btn-primary" to={`/peer-coaching/${activeConversation.id}`}>
                      View conversation recap
                    </Link>
                  )}
                </div>
              )}
            </Collapse>
          </div>
        );
      })}
    </div>
  );
};

export default SetCard;
