import React from 'react'
import {composeComponent} from 'utils/react-tools'
import * as _ from 'ramda'
import {fetchUser} from 'auth/requests'
import {withHandlers, withStateHandlers, lifecycle, withState, withProps, mapProps} from 'recompose'
import Modal from 'theme/Modal'
import Rating from './Rating'
import {getConversationsDefinitions, getConversations, saveCompetencies, getFinishedSets} from './requests'

import './Competencies.sass'

export default composeComponent 'Competencies',
  withState 'modalDismissed', 'dismissModal', false
  withStateHandlers ({programs, user, conversations}) ->
    user: user
    loading: true
    saving: false
    conversations: conversations ? []
    programs: programs ? []
    ratings: {}
    ratedSets: []
    error: null
  ,
    setUser: -> (user) -> {user}
    endLoading: -> -> loading: false
    startLoading: -> -> loading: true
    setSaving: -> (saving) -> {saving}
    setConversations: -> (conversations) -> {conversations}
    setPrograms: -> (programs) -> {programs}
    setRatedSets: -> (ratedSets) -> {ratedSets}
    setRating: -> (ratings) -> {ratings}
    setError: -> (error) -> {error}

  mapProps _.evolve
    programs: _.map (program) ->
      _.merge program, sets: program.sets?.map (set) ->
        _.merge set,
          programSetLabel: "#{program.label}/#{set.label}"

    conversations: _.map (conversation) ->
      [programLabel, setLabel, convLabel] = _.split '/', conversation.label
      _.merge conversation, programSetLabel: "#{programLabel}/#{setLabel}", convLabel: convLabel

  withHandlers
    loadData: ({user, setError, setUser, startLoading, endLoading, setConversations, setPrograms, programs, setRatedSets}) -> ->
      startLoading()

      u = Promise.resolve(user ? fetchUser())
      .then setUser

      convs = Promise.resolve(if conversations?.length then conversations else getConversations())
      .then setConversations

      p = Promise.resolve(if programs?.length then programs else getConversationsDefinitions())
      .then setPrograms

      finished = getFinishedSets()
      .then setRatedSets

      Promise.all [u, convs, p, finished]
      .then endLoading, setError

    isConversationFinished: ({user}) -> (conversation) ->
      me = conversation.participant.find (p) -> p.member.slug is user?.slug
      me?.state is 'Finished'

    setRating: ({setRating, ratings}) -> (set, competency, rating) ->
      if ratings[set.programSetLabel]
        setRating _.evolve(
          "#{set.programSetLabel}": _.assoc competency, rating
          ratings
        )
      else
        setRating _.assoc set.programSetLabel, _.objOf(competency, rating), ratings

  withHandlers
    setRating: ({setRating}) -> (set, competency) -> (rating) ->
      setRating set, competency, rating

  withProps ({programs, conversations, ratedSets, user, isConversationFinished, ratings, loading, modalDismissed}) ->
    sets = programs.reduce(
      (res, p) => res.concat(p.sets)
      []
    ).filter((s) => s.competencies?.length)

    oneMonthAgo = new Date().setDate(new Date().getDate()-30)/1;
    sixMonthsAgo = new Date().setDate(new Date().getDate()-180)/1;

    oneMonthAgo = new Date().setDate(new Date().getDate()-30)/1;
  
    allGroupedConversationsBySet = _.groupBy(
      (a) -> a.programSetLabel
      conversations.filter((conv) => conv.createDate > sixMonthsAgo)
    )

    groupedSets = _.mapObjIndexed(
      (group) ->
        group.reduce(
          (uniqGroups, conv) ->
            firstUniq = uniqGroups.findIndex((group) -> !group.find((c) -> c.label == conv.label))
            if(firstUniq > -1)
              _.adjust(firstUniq, _.concat([conv]), uniqGroups)
            else
              uniqGroups.push([conv])
              uniqGroups
          []
        )
      allGroupedConversationsBySet
    )

    now = new Date();

    finishedSetsCount = _.mapObjIndexed(
      (setGroups, setLabel) ->
        setGroups.filter((group) -> group.every(isConversationFinished) && group.some((convo) => convo.modifiedAt > oneMonthAgo)).length
      groupedSets
    )

    recentRatedSets = ratedSets.filter((rating) => rating.createDate > oneMonthAgo)

    ratedSetsCount = _.countBy(
      (ratedSet) -> ratedSet.peerConversationSetLabel
      recentRatedSets
    )

    endedSets = Object.keys(finishedSetsCount).reduce(
      (endedSets, programSetLabel) ->
        timesFinishedNotRated = finishedSetsCount[programSetLabel] ? 0 - (ratedSetsCount[programSetLabel] ? 0)
        if(timesFinishedNotRated > 0)
          set = sets.find((s) -> s.programSetLabel == programSetLabel)
          endedSets.concat({...set, timesFinishedNotRated})
        else endedSets
      []
    )

    warning = endedSets.find (set) ->
      set.competencies.find (c) -> not ratings?[set.programSetLabel]?[c]?

    endedSets: endedSets
    saveDisabled: warning
    shouldOpenCompetenciesModal: !loading && Boolean(endedSets?.length) && !modalDismissed

  withHandlers
    saveCompetencies: ({endedSets, ratings, loadData, setError, setRatedSets, setSaving, dismissModal, setIsCompetenciesModalOpen}) -> ->
      setSaving(true)
      endedSetsInstances = endedSets.reduce(
        (sets, set) ->
          sets.concat(new Array(set.timesFinishedNotRated).fill(set))
        []
      )

      Promise.all endedSetsInstances.map (set) ->
        saveCompetencies({
          label: set.programSetLabel
          competenciesRates: set.competencies.map (c) ->
            competencyLabel: c
            competencyRate: ratings[set.programSetLabel]?[c]
        }).then( ->, -> dismissModal(true))
      .then ->
        getFinishedSets()
        .then setRatedSets
        .then ->
          dismissModal(true)
          setSaving(false)
          if (setIsCompetenciesModalOpen)
            setIsCompetenciesModalOpen(false)

      .catch (error) ->
        setError(error)
        setSaving(false)
        dismissModal(true)
        if (setIsCompetenciesModalOpen)
          setIsCompetenciesModalOpen(false)

  lifecycle
    componentDidMount: ->
      @props.loadData()

    componentDidUpdate: (prevProps) ->
      if ((prevProps.shouldOpenCompetenciesModal != @props.shouldOpenCompetenciesModal) && (prevProps.setIsCompetenciesModalOpen && @props.shouldOpenCompetenciesModal))
        prevProps.setIsCompetenciesModalOpen(true)

  ({
    t
    programs
    endedSets
    setRating
    ratings
    saveCompetencies
    error
    saving
    ratedSets
    saveDisabled
    shouldOpenCompetenciesModal
  }) ->
    React.createElement("div", {"className": "Competencies__container"},
      React.createElement(Modal, { \
        "isOpen": (shouldOpenCompetenciesModal),  \
        "className": "Competencies",  \
        "ariaMessage": "Click the stars on the right to rate the following possible outcomes of this conversation."
      },
        React.createElement("h2", null, "Congratulations!"),
        React.createElement("p", null, """
          You have completed this conversations set""", (if endedSets?.length > 1 then 's' else ''), """.
          Please take a moment to rate the following possible outcomes.
"""),
        React.createElement("div", {"className": "Competencies__sets"},
          (endedSets?.map (set) ->
            React.createElement("div", {"className": "Competencies__set", "key": (set.label)},
              React.createElement("h3", null,
                (t.find "peerconversation.set.#{set.label}.title")
              ),
              (set.competencies?.map (competency) ->
                React.createElement("div", {"className": "Competencies__competency", "key": (competency)},
                  React.createElement("div", {"className": "Competencies__competencyName"},
                    React.createElement("h4", null, (t.find "competency.#{competency}.description"))
                  ),
                  React.createElement("div", null,
                    React.createElement(Rating, { \
                      "value": (ratings?[set.programSetLabel]?[competency]),  \
                      "onChange": (setRating set, competency)
                    })
                  )
                )
              )
            )
          ),
          (if error
            React.createElement("div", {"className": "Competencies__error"}, """
              Something went wrong while saving. Please try again later or contact Imperative Team.
""")
          ),
          React.createElement("div", {"className": "text-center"},
            React.createElement("button", { \
              "onClick": (saveCompetencies),  \
              "disabled": (saveDisabled || saving),  \
              "className": "btn btn_secondary btn_solid_bluePurple"
            },
              (if saving
                React.createElement("i", {"className": "fas fa-circle-notch fa-spin"})
              else 'Save'
              )
            )
          )
        )
      )
    )
