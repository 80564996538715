import { useEffect, useMemo, useState } from 'react';
import posthog from 'posthog-js';
import { useStore } from 'stores';

const POSTHOG_FEATURE_NAME = 'custom-pcp-url';
const DEFAULT_PCP_URL = window.config && window.config.pcp && window.config.pcp.address;

export const usePcpV2Url = () => {
  const { userStore } = useStore();

  const [payload, setPayload] = useState(null);
  const user = useMemo(() => userStore.user, [userStore.user]);

  useEffect(() => {
    if (user) {
      posthog.setPersonPropertiesForFlags({ slug: user.slug });
      posthog.onFeatureFlags(() => {
        if (posthog.isFeatureEnabled(POSTHOG_FEATURE_NAME)) {
          const flagPayload = posthog.getFeatureFlagPayload(POSTHOG_FEATURE_NAME);
          setPayload(flagPayload);
        } else {
          setPayload(DEFAULT_PCP_URL);
        }
      });
    }
  }, [user]);

  return payload;
};
